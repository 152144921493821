<template>
  <v-card>
    <v-card-text>
      <v-data-table
        disable-sort
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        item-key="reference_no"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Banka Hareketleri"
            icon="bank-circle-outline"
            @clear-filters="$refs.headSearch.reset"
            hide-edit
            @reload="loadList"
          />

          <v-row dense>
            <v-col>
              <rs-action
                v-if="selectedItems.length === 1"
                class="me-1 mb-1"
                @click.prevent="handleCollectingFormClick"
                label="Tahsilat Eşleştir"
                :loading="isLoading"
                tooltip="Sakin ve bağımsız bölüm seçerek tahsilat işleyin"
              >
                <!-- Tahsilat Eşleştir -->
                <v-icon small>mdi-connection</v-icon>
              </rs-action>
            </v-col>
          </v-row>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.activity_on="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price colored :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.transaction_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
      </v-data-table>
    </v-card-text>

    <BankTransactionToPaymentModal
      ref="transactionToPaymentForm"
      @saved="loadList"
    />
  </v-card>
</template>

<script>
import { hasDataTable, isPage } from "@/view/mixins";
import { BankTransactionToPaymentModal } from "@/view/pages/admin/components";

export default {
  name: "BankActivityList",
  mixins: [hasDataTable, isPage],
  props: {
    listType: {
      type: String,
      required: false,
      default: "open",
    },
  },
  components: {
    BankTransactionToPaymentModal,
  },
  data() {
    return {
      pageMeta: {
        title: "Banka Hareketleri",
      },
      options: {
        sortBy: ["activity_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Tarih",
          value: "activity_on",
          searchable: false,
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: false,
          align: "end",
        },
        {
          text: "Banka Bakiye",
          value: "balance",
          searchable: false,
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: false,
        },
        {
          text: "IBAN",
          value: "iban",
          searchable: false,
        },
        {
          text: "TCKN",
          value: "identity_number",
          searchable: false,
        },
        {
          text: "VKN",
          value: "tax_number",
          searchable: false,
        },
      ],
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query("api-super-admin/bank-activities", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCollectingFormClick(item) {
      this.$refs.transactionToPaymentForm.show(
        item.id ? item : this.selectedItems[0]
      );
    },
  },
};
</script>
